@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500;600;700;800&display=swap');

*,
*::before,
*::after {
  font-family: 'Poppins', sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-weight: normal;
  font-display: swap;
}

:root {
  --PAGE_CONTENT_SPACING: 4vw;

  --CAMSOL_PRIMARY_100: #d7e0e4;

  --CAMSOL_NEUTRAL_50: #f4f4f4;
  --CAMSOL_NEUTRAL_100: #e2e2e2;
  --CAMSOL_NEUTRAL_200: #c5c5c5;
  --CAMSOL_NEUTRAL_300: #a9a9a9;
  --CAMSOL_NEUTRAL_400: #8d8d8d;
  --CAMSOL_NEUTRAL_500: #717171;
  --CAMSOL_NEUTRAL_600: #545454;
  --CAMSOL_NEUTRAL_700: #383838;
  --CAMSOL_NEUTRAL_800: #1c1c1c;

  --CAMSOL_ERROR: #ff4e4e;
  --CAMSOL_ERROR_DARK: #db3535;

  /* --INNOVATION_SECONDARY_50: #ebf6f5;
  --INNOVATION_SECONDARY_100: #d8edeb;
  --INNOVATION_SECONDARY_200: #a4d5d2;
  --INNOVATION_SECONDARY_300: #69b9b4;
  --INNOVATION_SECONDARY_400: #36a19a;
  --INNOVATION_SECONDARY_500: #009fe3;
  --INNOVATION_SECONDARY_600: #036e67;
  --INNOVATION_SECONDARY_700: #4c4c4c; */

  /* --TECHNOLOGY_SECONDARY_50: #ebf6f5; */
  --INNOVATION_SECONDARY_100: #ebf7fd;
  --INNOVATION_SECONDARY_200: #a3dcf5;
  --INNOVATION_SECONDARY_300: #67c6ee;
  --INNOVATION_SECONDARY_400: #33b2e9;
  --INNOVATION_SECONDARY_500: #009fe3;
  /* --INNOVATION_SECONDARY_600: #007fb6; */
  --INNOVATION_SECONDARY_600: #007dfb;
  --INNOVATION_SECONDARY_700: #005f87;
  --INNOVATION_SECONDARY_800: #004c6d;
  --INNOVATION_SECONDARY_900: #203656;

  --FOUNDATION_SECONDARY_50: #fff6ee;
  --FOUNDATION_SECONDARY_400: #fd9f4d;
  --FOUNDATION_SECONDARY_500: #fd8721;

  --RED_COLOR: #d30b0b;
  --RED_60: #ff5247;

  --GREEN_COLOR: #159c09;
  --GREEN_60: #3caf50;

  --GRAY-COLOR: #878787;
  --GRAY-03: #9f9f9f;
  --GRAY-05: #e8e8e8;
  --GRAY-10: #878787;
  --GRAY-40: #e3e5e6;

  --PRIMARY-COLOR: #003952;
  --PRIMARY-COLOR_50: #ebeff1;
  --PRIMARY-COLOR_100: #d7e0e4;
  --PRIMARY-COLOR_200: #a3b8c1;
  --PRIMARY-COLOR_300: #678998;
  --PRIMARY-COLOR_400: #336175;
  --PRIMARY-COLOR_500: #003952;
  --PRIMARY-COLOR_600: #002e42;
  --PRIMARY-COLOR_700: #002231;
  --PRIMARY-COLOR_800: #001b27;
  --PRIMARY-COLOR_900: #00151e;

  --DARK_20: #6c7072;
  --DARK_40: #e3e5e6;
  --DARK_60: #303437;
  --DARK_100: #090a0a;
  --DARK_10: #72777a;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
}

body {
  width: 100%;
}

img,
figure {
  width: 100%;
}

input {
  background-color: var(--WHITE);
  outline: unset;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}

input:-webkit-autofill,
:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 0px 1000px #fff inset;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  overflow: hidden;
}

input:focus {
  outline: unset;
}

input[type='checkbox'] {
  opacity: 0;
}
